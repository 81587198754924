import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router";
import LabelBottomNavigation from "../components/bottomnav";
import Paper from '@mui/material/Paper';
import FeatherIcon from 'feather-icons-react';
import axios from '../components/axios';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Container } from "@mui/material";
import Card from '@mui/joy/Card';
import { RWebShare } from "react-web-share";
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import InputBase from '@mui/material/InputBase';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useUserAuth } from "../context/UserAuthContext";
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import AspectRatio from '@mui/joy/AspectRatio';






const Earn = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [playLoad,setData] = useState({"authtoken":user.accessToken,"type":"getReferral"})
  const [loading, setLoading] = useState(true);
  const [refcount, setCount] = useState(false);
  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));
  
  useEffect(() => {
    const getreferrals = async () => {
      document.body.style.background = '#ffffff';
        try {
          const { data } = await axios.post(
            '/referralapi', playLoad, { "Content-Type": "application/json" } );
          console.log(data)
          setRecords(data.referrals);
          if ((data.referrals).length !== 0){setCount(true)}
          console.log(refcount)
        } catch (error) {
          console.log("records error");
        }
        setLoading(false);
      };
      if (loading === true){
    getreferrals();
      }
    },[]);
  return (
    <>
        <Paper sx={{ position: 'fixed', top: 0, left: 0, right: 0 ,zIndex:'1100'
}} elevation={0}>
    <AppBar position="static" elevation={0} sx={{ bgcolor: "white"}} >

        <Toolbar>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1, color: "#001533",fontSize:'25px', fontWeight:400}}>
            Earn
          </Typography>
          
          <IconButton sx={{ p: 0 }}>
            <FeatherIcon icon='search' />
              </IconButton>
              
              
        </Toolbar>
      </AppBar>
      </Paper>
      <Container style={{
        height: 'auto',
        padding:'0.5rem',
        top:65 , left: 0, right: 0 ,
        position: 'absolute',
        background:'#ffffff',
        maxWidth:"500px"
        
      }}>
         <Card

sx={{
  width: '100%',
  bgcolor: 'initial',
  boxShadow: 'none',
  '--Card-padding': '0px',
}}

>
<Box sx={{ position: 'relative' }}>
  <AspectRatio ratio="4/2">
    <figure>
    {loaded ? null : (
        <Skeleton variant="rectangular" sx={{ width: '100%', height:'359px', borderRadius:'16px'}} />
      )}
    <img
            style={loaded ? {} : { display: 'none' }}
            src="https://firebasestorage.googleapis.com/v0/b/finplay-a9e3a.appspot.com/o/3.png?alt=media&token=c402dcad-9ad2-4ede-bba1-2fb02746e372"
            srcSet="https://firebasestorage.googleapis.com/v0/b/finplay-a9e3a.appspot.com/o/3.png?alt=media&token=c402dcad-9ad2-4ede-bba1-2fb02746e372"
            onLoad={() => setLoaded(true)}
            alt=""
          />
    </figure>
  </AspectRatio>
</Box>
</Card>
      <Paper
      component="form"
      sx={{mt:2, mb:2,display: 'flex', alignItems: 'center', width: "100%",border: (theme) => `1px solid ${theme.palette.divider}`,
      borderRadius: 1,}}
      elevation={0}
      
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder ={`app.finplay.in/signup/${user.uid}`}
        inputProps={{ 'aria-label': 'Refferral Code' }}
        disabled
      />
      <RWebShare
        data={{
          text: "Learn, Invest, Earn",
          url: `https://app.finplay.in/signup/${user.uid}`,
          title: "Finplay Invite Share",
          disableNative:false,
          
        }}
        onClick={() => console.log("shared successfully!")}
      >
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <FeatherIcon icon='share-2'  size='20'/>
      </IconButton>
      </RWebShare>
           
      

    </Paper>

    <Paper  elevation={0} style={{maxHeight:'18rem', overflow: 'auto'}}>
    {refcount ? (<>
      <Typography component="div" sx={{mt:"0.5rem",mb:"0.5rem",textAlign: 'center', flexGrow: 1, color:"#001533", fontSize:16,fontWeight:600}}>
      Your Referrals
    </Typography>
    <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Demo>
            <List >
             {records.map((referral, index) => <>
              <Divider variant="middle" />
      <ListItem>
        <ListItemAvatar> 
          <Avatar alt="Finplay" src={referral.profileImg}/>
        </ListItemAvatar>
        <ListItemText
          primary={referral.userPhone}
          secondary={referral.signupDate}
        />
      </ListItem>
      </>)
  }
            
            </List>
          </Demo>
        </Grid>
        </Grid></>) :(<>
<Typography component="div" sx={{mt:"0.5rem",textAlign: 'center', flexGrow: 1, color:"#6F767E", fontSize:16,fontWeight:600}}>
  No Referrels Yet 
</Typography>

<Typography component="div" sx={{mt:"0.5rem", textAlign: 'center', flexGrow: 1, color:"#6F767E", fontSize:14,fontWeight:600}}>
  Share Invite Link Now.
</Typography>
</> )}
      
        </Paper>
      <Toolbar sx={{mb:2}}>{/* content */}</Toolbar>
      </Container>
      <div >
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex:'1100' }} elevation={3}>
      <LabelBottomNavigation active="/earn"/>
      </Paper>
      </div>
    </>
  );
};

export default Earn;
