import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import { Container } from "react-bootstrap";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { useUserAuth } from "../context/UserAuthContext";
import LoadingButton from '@mui/lab/LoadingButton';



const PhoneSignUp = () => {
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [flag, setFlag] = useState(false);
  const [otp, setOtp] = useState("");
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);
  const [textColor, setText] = useState('#FFFFFF');
  const [bgColor, setbg] = useState('#393798');
  const [referralCode, setReferral] = useState(localStorage.getItem('referralCode'));
  let { code } = useParams();
  const { setUpRecaptha } = useUserAuth();
  const navigate = useNavigate();
  
  useEffect(() => {
    const setCode = async () => {
      console.log('flag4')
      if (typeof(code) !== 'undefined'){
        try {
          console.log(code)
          setReferral(code);
          localStorage.setItem('referralCode', JSON.stringify(referralCode));
        } catch (error) {
          console.log("records error");
        }  
    }
      if (typeof(code) === 'undefined'){
        try {
          console.log('flag1')
          setReferral(localStorage.getItem('referralCode'));
        } catch (error) {
          console.log(error);
        }  
       
      }
  };

      if (referralCode === 'undefined' || typeof(code) !== 'undefined'){
        console.log('flag3')
        setCode();
      }
    });

  const getOtp = async (e) => {
    var fromatNumber='+'+number 
    e.preventDefault();
    
    setError("");
    if (number === "" || number === undefined)
      return setError("Please enter a valid phone number!");
    try {
      setText('#D5D5E9');
      setbg('#D5D5E9');
      setLoading(true);
      const response = await setUpRecaptha(fromatNumber);
      setResult(response);
      setFlag(true);
    } catch (err) {
      setError(err.message);
      setText('#FFFFFF');
      setbg('#393798');
      setLoading(false);
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setError("");
    if (otp === "" || otp === null) return;
    try {
      await result.confirm(otp);
      navigate("/",{ replace: true });
    } catch (err) {
      setError(err.message);
    }
  };

 
  
  return (
    <>
     <Container style={{ maxWidth: "450px", height:"100%",}}>
       <div className="row">
       <div className="col">
     <div className="p-2 mt-2 d-flex  flex-row-reverse fixed-top">
      <Link to="/">
      <h5 style={{color:'#000000'}}>X</h5>
      </Link>
      </div>
      </div>
      </div>
      <div className="row">
        <div className="div" style={{ width: "100%",padding:'1rem'}}>

      
    
        
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={getOtp} style={{ display: !flag ? "block" : "none" }}>
        <h7  style={{color:"#001533"}}>Log into your account</h7>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <div class="d-grid gap-2 mt-4">
            <PhoneInput 
              country={'in'}
              value={number}
              onChange={setNumber}
              dropdownStyle={{height:'500px'}}
              inputStyle={{width:'100%'}} 
              containerClass={{background:'#f4f4f4'}}
            />
            </div>
           
          </Form.Group>
          <div className="mt-1 mb-1" style={{color:"#001533"}}>
          We will send an OTP to your mobile number
      </div>
          <div className="d-grid gap-1 mt-4">
          
            <LoadingButton
          loading={loading}
          disabled={loading}
          loadingPosition="center"
          size="large"
          fullWidth={true}
          type="submit" 
          id="sign-in-button" 
          style= {{"backgroundColor":`${bgColor}`,
          "border":"none",
          "width":"100%",
          "color":`${textColor}`,
          "padding":"15px",
          "textAlign":"center",
          "textDecoration":"none",
          "display":"inline-block",
          "fontSize":"16px",
          "margin":"4px 2px",
          "borderRadius":"75px"}}
        >
          CONTINUE
        </LoadingButton>

          </div>
          <div id="recaptcha-container"></div>
        </Form>

        <Form onSubmit={verifyOtp} style={{ display: flag ? "block" : "none" }}>
        <h7  style={{color:"#001533"}}>Enter OTP sent to your phone number {'+'+number}</h7>
        
          <Form.Group className="mb-2" controlId="formBasicOtp">
            
            <div class="d-grid gap-2 mt-4">
            <Form.Control
              type="otp"
              style={styles.otpInput}
              placeholder="Enter OTP"
              onChange={(e) => setOtp(e.target.value)}
            />
            </div>
           
          </Form.Group>
         
          <div className="d-grid gap-1 mt-4">
          
          <button type="submit" style={styles.LoginButton}>
           LOGIN
        </button>

      </div>
      <div className="mt-1 text-center" style={{color:"#001533"}}>
          Didn’t receive OTP ?
          <button class="btn bg-transparent" style={{color:'#0066F6'}} onClick={getOtp} >Resend</button>
      </div>
        </Form>
        </div>
    </div>
    </Container >
    </>
  );
};

export default PhoneSignUp;
const styles = {
  LoginButton: {"backgroundColor":"#393798",
                "border":"none",
                "width":"100%",
                "color":"white",
                "padding":"15px",
                "textAlign":"center",
                "textDecoration":"none",
                "display":"inline-block",
                "fontSize":"16px",
                "margin":"4px 2px",
                "borderRadius":"75px"},
  otpInput:{"width":"100%","padding":"18.5px 20px","margin":"8px 0","display":"inline-block","border":"1px solid #ccc","borderRadius":"4px","boxSizing":"border-box"}
  
}