import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router";
import {getOnboardingData} from "../service/cms";
import OnBoarding from "../components/onboaring";
import DotLoader from "../components/loader";
 
const Login = () => {
  const { logIn, googleSignIn } = useUserAuth();
  const navigate = useNavigate();
  const [slidesArray, setslidesArray] = useState([]);
  const [loading, setLoading] = useState(true);

    useEffect(() => {
      const getSlides = async () => {
          try {
            const slides = await getOnboardingData()
            console.log(slides.length)
            
            setslidesArray(slides);
            if (slidesArray.length !== 0){
            console.log(slidesArray)
            setLoading(false);
          }
        } catch (error) {
            console.log("records error");
          }
          
        };
        if (loading === true){
          getSlides();
        }
      });

  
  return (
    <>
    {loading ? (<>
        <DotLoader/>
          </>) : (
        <Container style={{ maxWidth: "450px", height:"100%", padding:'1rem'}}>
        <div className="row mb-3">
          <div className="col">
          <div className="mx-auto d-flex justify-content-center">       
          <OnBoarding onBoarding={slidesArray}/>
      </div>
      </div>
  </div>
  <div className="row align-items-end positon-absolute">
          <div className="col mt-5"> 
        <div className="d-grid">
            
              <button onClick={()=>{navigate('/signup',{ replace: true })}} style={styles.LoginButton}>
                LOGIN WITH PHONE NUMBER
              </button>
          </div>
          <div className="p-4  mt-1 text-center">
         By Clicking, Get Started, you agree to the 
       <Link to="/terms"> Terms and Conditions</Link>
        </div>
        </div>
      </div>
     
      </Container>)}
     
    </>
  );
};

export default Login;

const styles = {

  LoginButton: {"backgroundColor":"#393798",
                "border":"none",
                "width":"100%",
                "color":"white",
                "padding":"15px",
                "textAlign":"center",
                "textDecoration":"none",
                "display":"inline-block",
                "fontSize":"16px",
                "margin":"4px 2px",
                "borderRadius":"75px"}
}
