import React from "react";
import { Container } from "react-bootstrap";
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router";
import FeatherIcon from 'feather-icons-react';

function Terms() {
   const navigate = useNavigate();

   return (<>
      <Paper sx={{ position: 'fixed', top: 0, left: 0, right: 0 ,zIndex:'1100'}} elevation={0}>
      <AppBar position="static" elevation={0} sx={{ bgcolor: "white"}} >
  
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: "#001533",fontSize:'25px', fontWeight:600}}>
              Finplay
            </Typography>
            
            <IconButton onClick={()=>{navigate('/login')}} sx={{ p: 0 }}>
              <FeatherIcon icon='x' />
                </IconButton>
          </Toolbar>
        </AppBar>
        </Paper>
      <Container style={{  position:'absolute', top:85 , left: 0, right: 0, maxWidth: "650px", height:"100%"}}>
         
      <div>
         <header class="d-flex justify-content-center" >
            <h2>Terms of Use and Agreement</h2>
         </header>
         <div class="mt-3" >
         <Typography variant="subtitle1" sx={{ flexGrow: 1, color: "#001533", fontSize:14,fontWeight:400}} component="div">
            Thank you for selecting the Services offered by FinPlay Technologies Private Limited and/or its subsidiaries and affiliates (hereinafter referred to as ” FinPlay “, “our”, or “us”) via its website www.finplay.in and its mobile application(s). (“ FinPlay ”)
         </Typography>
         <br/>
         <Typography variant="subtitle1" sx={{ flexGrow: 1, color: "#001533", fontSize:14,fontWeight:400}} component="div">
         This document is an electronic record, being generated by a computer system and it does not require any physical or digital signature. This document is published in accordance with Information Technology guidelines for intermediaries and contains the rules and regulations and user agreement for accessing and using FinPlay.
         </Typography>
         <br/>
         <Typography variant="subtitle1" sx={{ flexGrow: 1, color: "#001533", fontSize:14,fontWeight:400}} component="div">
         Kindly review these Terms of Use and Agreement (hereinafter referred to as “Services Agreement”) thoroughly. By proceeding, accepting electronically (for example, clicking “I Agree”), after installing the mobile application, accessing the website with your user ID and password or using the Services, you agree to these Terms of Use and Agreement. You may be able to have access to, or use the Services only if you enter into the Services Agreement.         
         </Typography>
         <Typography variant="subtitle1" sx={{ flexGrow: 1, color: "#001533", fontSize:14,fontWeight:400}} component="div">
         This Services Agreement (“Agreement”) entered into <br/><br/> BY AND BETWEEN<br/> <br/>FinPlay technologies Private Limited , a company incorporated under the provisions of the Companies Act, 2013, having its registered office at 36/50, S.N.Colony, Rayachoty, Kadapa, Andhra pradesh, India Pin 516269, (hereinafter referred to as “ FinPlay ” (which expression shall, unless repugnant to or inconsistent with the context or meaning thereof, be deemed to mean and include its successors and permitted assigns) of the ONE PART.
         </Typography>
         <Typography variant="subtitle1" sx={{ flexGrow: 1, color: "#001533", fontSize:14,fontWeight:400}} component="div">
         AND<br/> <br/>Customer, (hereinafter referred to as “the Client”, “I”, “We”, “My”, ”Our”, “their”, “them” which expression shall, unless repugnant to or inconsistent with the context or meaning thereof, be deemed to mean and include his/her heirs, executors, administrators and legal representatives and permitted assigns of the OTHER PART, sets forth the terms and conditions upon which the Client will be able to avail services (defined herein below) through Finplay.
         </Typography>
         <Typography variant="subtitle1" sx={{ flexGrow: 1, color: "#001533", fontSize:14,fontWeight:400}} component="div">
         In this Agreement, unless the context otherwise requires, FinPlay and the Client shall hereinafter be jointly referred to as the “Parties”, and severally as the “Party”.
         <br/><br/>The Client is desirous of availing services offered on FinPlay
         </Typography>
         <Typography variant="subtitle1" sx={{ flexGrow: 1, color: "#001533", fontSize:14,fontWeight:400}} component="div">
         NOW, THEREFORE, in consideration of the mutual agreements set forth herein, the Parties agree as follows:         
         <br/><br/>1. Definitions<br/><br/>In this Agreement the following Terms shall have the following meanings:
         </Typography>
         <Typography variant="subtitle1" sx={{ flexGrow: 1, color: "#001533", fontSize:14,fontWeight:400}} component="div">
         <li>“Applicable Laws” means any applicable Indian statute, law, ordinance, regulation, rule, order, bye-law, administrative interpretation, writ, injunction, directive, judgment or decree or other instrument which has a force of law in India, as is in force from time to time.</li>         
         <li>“Business Day” means any day, which is not a Saturday, Sunday or any other public or gazetted holiday as declared by the Government and when both banks and markets are operational.</li>         
         <li>“Confidential Data” means the information related to the Client which the Client shares with Finplay or submits on Finplay and the proprietary information relating to Finplay which the Client may come in possession of under or pursuant to this Agreement.</li>
         <li>“Government” means any Indian central, state or local government authority, agency, branch or body or any instrumentality thereof.</li>
         <li>“Investment Advice” means advice relating to investing in, purchasing, selling or otherwise dealing in securities or investment products, and advice on investment portfolio containing securities or investment products, whether written, oral or through any other means of communication for the benefit of Client and shall include Financial Planning, as defined in Regulation 2 (1) (l)of the Regulations, as amended from time to time.</li>
         <li>“Regulations” means the Securities and Exchange Board of India (Investment Advisers) Regulations, 2013, as amended and modified from time to time, including the circulars and notifications issued pursuant thereto.</li>
         <li>“Access Credentials” shall mean the user ID and password used by a Client to set-up, access and operate their Finplay . Further, clients agree and unconditionally confirm that they shall protect and keep confidential their Access Credentials at all times and any disclosure to third parties shall be entirely at their risk. Clients’ shall ensure that their Access Credentials are not disclosed to any other person.</li>
         <br/>
         </Typography>
         <Typography variant="subtitle1" sx={{ flexGrow: 1, color: "#001533", fontSize:16,fontWeight:600}} component="div">
         Terms and conditions for transaction services in mutual funds
         </Typography>
         <br/>
         <Typography variant="subtitle1" sx={{ flexGrow: 1, color: "#001533", fontSize:14,fontWeight:400}} component="div"><ol>
         <li value="1">FinPlay is registered with AMFI with member code 192179. This arrangement enables Finplay to facilitate online transactions in select schemes of select Mutual Funds as may be available through the Aggregator, and Clients shall be able to transact in Mutual Funds schemes that are available on and enabled by these platforms, through its website www.finplay.in and/or its mobile application.</li>
         <li>The Aggregator also provides an online payment facility through tie-ups with payment services providers that enable Finplay to facilitate its clients in making payments for Mutual Fund purchases through various payment modes including Net Banking, NEFT/RTGS, UPI and NACH/ACH/E-NACH mandates. Accordingly, Finplay , through the Aggregator, supports payments only through supported Banks and payment methods for Mutual Fund purchase transactions.</li>
         <li>Instant Redemption facility : In addition to the above, Finplay has entered into direct arrangements with select Mutual Fund companies for offering an Instant Redemption facility for select schemes of these Mutual Fund companies. These arrangements are without prejudice and based on the availability of such a service from the Mutual Fund company and the technological support offered by the Mutual Fund company to facilitate Instant Redemption through Finplay.</li>
         <li>KYC Arrangements: Finplay is registered with NDML KRA, CVL KRA and with CERSAI as an intermediary for verification, registration and update of KYC records of Clients. To comply with KYC (Know your Client/Customer) and KRA requirements as required under SEBI (KYC Registration Agency) Regulation 2011, SEBI Act 1992, Prevention of Money Laundering Act, 2002, all laws and regulations including but not limited to the Foreign Exchange Management Act, Reserve Bank of India (RBI) guidelines, to the extent applicable for investing in Mutual Funds, Finplay may require Clients to provide necessary information and documents for registration and/or updation of their KYC records. Being completely digital, Finplay cannot provide KYC services electronically to all Clients and may direct such Clients to directly reach out to physical points of presence of CAMs or Karvy or such third parties in cases where Clients are unable to meet the minimum threshold required for digitally completing the KYC registration or for updation of KYC. This may include but not limited to Clie nts not having an Aadhaar Card, or Clients ’ not having a mobile number, accessible to them, mapped to their Aadhaar Card. In such cases, where the Client’s KYC verification is not available, KYC registration is incomplete, on hold, outdated or KYC update is not possible, Finplay shall, entirely in its discretion, refuse to provide one or any service to such Clients.</li>
         <li>Finplay offers access to regular Plans of Mutual Funds through Finplay and receives nominal commissions from Mutual Funds for transactions facilitated through Finplay in schemes offered by these Mutual Funds.</li>
         <li>This facility is offered by Finplay to Clients as an integrated platform to transact in regular Plans of Mutual Funds. Such services shall not, at any point in time, be construed as offering or furnishing of Investment Advice, Legal Advice, Tax Advice or Financial Advice or any Advice of similar nature. Clients who avail of the transaction facility for Mutual Funds should inform themselves of the risks of investing in financial instruments of any kind prior to proceeding with a transaction.</li>
         <li>Finplay does not make any claims with respect to the performance of any Mutual Fund schemes and shall not have any liability towards any losses that Clients may incur as a direct or indirect result of their decision, to purchase, to redeem, to not purchase or to not redeem units, in any Mutual Fund scheme.</li>
         <li>Registration Related Conditions</li>
         <li>Clients shall be required to complete an onboarding process before they can start transacting in Mutual Fund schemes. The onboarding process will include collection and verification of KYC and Bank Information, Risk Profiling Information, FATCA/CRS information as required under the R egulations and/or by the Aggregator.</li>
         <li>In the event an account is jointly held, Clients’ acknowledge that all instructions (including instructions for modification of this clause) shall be given only by the first holder of such an account and for this purpose, the other holders of the account, irrevocably constitute the first holder above as their agent. All instructions given by the first holder above shall be binding on all the holders as if given by each of them personally. Clients’ hereby authorize Finplay to process and transmit such instructions given from time to time in relation to their transactions to the respective mutual funds / asset management companies. The mode of operation and holding in all joint accounts shall always be treated as “Either or Survivor”.</li>
         <li>Clients agree and authorize Finplay to submit the information collected above to the Aggregator and to the respective Mutual Funds/Asset Management Companies and/ or their Registrar and Transfer Agents for the purpose of validation and to comply with regulatory requirements as notified from time to time. Clients’ also agree that the signature available in the KRA records/systems would be used for signature verification for processing any request made by you through the Website or direct transactions with AMC and in the event such signature is not available or legible in KRA records/system, you understand and agree that the AMC shall be entitled to carry out further checks to validate the authenticity of the request / or to reject/ disallow any transaction in connection herewith.</li>
         <li>Clients agree that for any changes to information provided including change of address, mobile number, e-mail id, bank details etc may require a duly signed hard copy. Such changes can be made in the records of the respective entities’ only after they have received such a hard copy of the request and have satisfied themselves as to the validity of such request for modification or update of records .</li>
         </ol>
         <br/>
         </Typography>
         <Typography variant="subtitle1" sx={{ flexGrow: 1, color: "#001533", fontSize:16,fontWeight:600}} component="div">
         Terms and Conditions of use pertaining to Gold
         </Typography>
         <br/>
         <Typography variant="subtitle1" sx={{ flexGrow: 1, color: "#001533", fontSize:14,fontWeight:400}} component="div">
         These “Terms” and “Conditions of Use” (“Terms”) constitute an electronic record within the meaning of the applicable laws. This electronic record is generated by a computer system and does not require any physical or digital signatures. These Terms shall be in addition to and not in derogation to any other terms as stipulated from time to time. By using this facility on FinPlay , you are agreeing to and accepting these Terms.
      
         </Typography>
         <br/>
         <Typography variant="subtitle1" sx={{ flexGrow: 1, color: "#001533", fontSize:14,fontWeight:400}} component="div"><ol>
         <li value="1">FinPlay ’s role in relation to Gold is limited to facilitating a transaction with the Partner. Gold is not a product and/or service provided by FinPlay .</li>
         <li>Neither FinPlay nor the Partner has provided any Investment Advice to the user in connection with Gold and the user has made their own informed decision in relation to the same.</li>
         <li>FinPlay shall not in any way be deemed to be making any representation or warranty whatsoever in connection with Gold, including in relation to its adequacy or validity. FinPlay shall under no circumstances be responsible, directly or indirectly, for any deficiencies in the Partners’ product(s). Further, the user understands that FinPlay does not make any claims nor has any responsibility towards the performance of Gold.</li>
         <li>FinPlay does not guarantee, explicitly or implicitly, any returns, interest rate offered by the Partner(s).</li>
         <li>Definitions for all sub clauses of Clause 7,</li>
         <li>“You/you/Your”, or “ Customer ” shall mean the counterparty who transacts using FinPlay , being a natural person, buying Gold, selling back Gold to MMTC-PAMP .</li>
         <li>“Service Providers” mean independent third-party service providers, and</li>
         <li>“Partner”, “Seller”, “ MMTC-PAMP ”</li>
         <li>The Gold investment of an individual Customer identified through his/her FinPlay account based on the registered mobile number present in records of FinPlay .</li>
         <li>MMTC-PAMP or FinPlay may revise these Terms from time to time and at any time. Such changes shall be effective when posted on FinPlay and shall be deemed to be notified to the Customer. If you do not agree to be bound by these Terms and Conditions of Access and Use, as modified from time to time, you should leave FinPlay immediately.</li>
         <li>MMTC-PAMP/ FinPlay shall be entitled to collect and store relevant information and documents provided by the Customer. Further, as and when required by MMTC-PAMP / FinPlay based on the government regulations, the Customer will be required to provide additional documents for fulfilling the KYC requirements. You authorize FinPlay to make such inquiries as they may feel necessary to satisfy themselves about the validity of your identity. You undertake to update us and FinPlay in case of a change of Your existing information or the verification documents, if any, provided by You.</li>
         <li>You agree to provide true, accurate, current and complete information about yourself as prompted by FinPlay and maintain and promptly update to keep it true, accurate, current and complete. If you have reasons to believe that there is an error in the information furnished to FinPlay You shall immediately advise the FinPlay in writing and forthwith provide correct/updated information.</li>
         <li>If you provide any information that is untrue, inaccurate, not current or incomplete, or FinPlay has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, / FinPlay has the right to suspend or terminate your gold transactions and refuse any and all current or future use of FinPlay (or any portion thereof) without any liability to you. You hereby undertake to indemnify and keep indemnified FinPlay against any and all losses, claims, liabilities costs, etc. which arise out of or relating to Your failure to identify Yourself or due to incorrect KYC documents/information.</li>
         <li>You can offer to buy Gold worth Rs. 10.00 (Rupees Ten Only) and above incremental value thereof at the live purchase price of Gold of 99.9% purity displayed on FinPlay . These prices are provided to FinPlay by MMTC-PAMP.</li>
         <li>Payment will be accepted only through the payment options made available on FinPlay . At the time of purchase / sale-back of Gold, the relevant taxes will be chargeable as per applicable Government regulations.</li>
         <li>For each confirmed purchase of Gold by the Customer, MMTC-PAMP will transfer the metal to the vaulting agencies and it will be held there, under the supervision of an independent trustee, till sell-back by the Customer.</li>
         <li>MMTC-PAMP may also, from time to time in the future, offer/permit the Customer to sell-back its gold in fraction to at prevailing live sell-back price of gold of 99.9% purity, during the offer period as may be announced/permitted. The money against the fractional gold sold by a Customer would be credited to the Customer.</li>
         <li>The live purchase price of Gold will be displayed on FinPlay and is subject to change from time to time. At the time you request sell-back, the value of Gold/quantity of Gold to be sold back will be displayed based on the live sell-back price of Gold. The live purchase price of Gold and live sell-back price of Gold may vary and also may be different from that available in the open market and/or in any other retail outlet. Your offer may be rejected irrespective of whether an item has been earmarked/wish listed by a Customer. In such an event if you still wish to offer to purchase Gold or sell-back residual fractional Gold, You may make another offer at the changed price.</li>
         </ol>
         </Typography>
      </div>
      </div>
      <Toolbar sx={{mb:3}}>{/* content */}</Toolbar>
      </Container>
      </>);
}
export default Terms;