import React,{useState, useEffect} from "react";

const savvyPayment = async (checkoutid,uuid,phoneNumber,panNumber,type,amount) => {
 
  const Script = document.createElement("script");
  Script.src = "https://cdn.savvyapp.in/lib/savvyJsSDK.min.js"; //"https://cdn.savvyapp.in/lib/savvyJsSDK.min.js"; "https://cdn.savvyapp.in/lib/savvyJsSDK.min.js";
  Script.defer = "defer";
  Script.async = true;
  Script.onload = () => {
    var config = {
      isProduction: true, // If hitting UAT or production
      uuid: `${checkoutid}`,//`${checkoutid}`, // UUID from generated link on dashboard
      partnerAccessKey: "15e3d97f-c1b1-4dc1-be22-4af2a8fac7e8", //"15e3d97f-c1b1-4dc1-be22-4af2a8fac7e8", // Partner access key provided to you
      type: "generic_checkout",
      amcCode: "",
      partnerTransactionId: `${uuid}`, // Unique ID generated by partner
      phoneNumber:  `${phoneNumber.slice(3)}`,
      panNumber:  `${panNumber}`,
      paymentSamePageRedirection: true,
      redirectUrl: "https://app.finplay.in/",
      defaultMandateMax: `${amount}`, // Default mandate maximum
      defaultTransactionType:`${type}`, // If you want to show SIP or lumpsum first

      onUserExit: (data) => {
        console.log("data====>>", data);
      },
      onError: () => {
        console.log("ON_ERROR_OCCURED");
      },
      onComplete: (data) => {
        console.log("data2====>>", data);
      },
      onPaymentUrlReceived: (data) => {
        console.log(data)
        console.log("ON_PAYMENT_URL_RECEIVED_OCCURED")
        window.location.href = data.url;
      }
    };
    // eslint-disable-next-line no-undef
    var savvy = new SavvySDK(config);
    savvy.start();
  };

  document.body.appendChild(Script);
  console.log(Script);
};


  export {savvyPayment};