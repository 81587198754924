import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import DotLoader from "./loader";

const ProtectedRoute = ({ children }) => {
  const { user }  = useUserAuth();
  if (user === false) {

    return <DotLoader/>;
  }
  if (user === null) {
    
    return  <Navigate to={'/login'} replace />;
  }
  return children;
};

export default ProtectedRoute;
