import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
const getOnboardingData = async () => {
    let slides = [];
    const snapshotData = await getCmsData("onboaring");
    if (snapshotData) {
      let orderedkeys = Object.keys(snapshotData).sort();
      orderedkeys.forEach((key) => {
        slides.push(snapshotData[key]);
      });
    }
    return slides;
  };
  
  const getCmsData = async (type) => {
    try {
      const docRef = doc(db, "CMS", type);
      const snapshot = await getDoc(docRef);
      return snapshot.data();
    } catch (error) {
      console.log("Data", error);
      return null;
    }
  };

  export {getOnboardingData};

