import React, { useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import Skeleton from '@mui/material/Skeleton';



const OnBoarding = (props) => {
    const carouselData=props.onBoarding
    const [loaded, setLoaded] = useState(false);

    return (
      <>
        <Carousel indicators = {false}>
      <Carousel.Item>
      <div className="mx-auto d-flex justify-content-center">
     
        <img
          className="d-block w-100"
          src= {carouselData[0].image}
          alt="First slide"
          onLoad={() => setLoaded(true)}
        />
        </div>
        <div className="d-grid text-center">

          <h3 style={{color:"#001533"}}>{carouselData[0].title}</h3>
          <p style={{color:"#001533"}}>{carouselData[0].content}</p>
       
        </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className="mx-auto d-flex justify-content-center">
      
        <img
          className="d-block w-100"
          src= {carouselData[1].image}
          alt="Second slide"
          onLoad={() => setLoaded(true)}
        />
        </div>
        <div className="d-grid text-center">

          <h3 style={{color:"#001533"}}>{carouselData[1].title}</h3>
          <p style={{color:"#001533"}}>{carouselData[1].content}</p>
       
        </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className="mx-auto d-flex justify-content-center">
     
        <img
          className="d-block w-100"
          src= {carouselData[2].image}
          alt="Third slide"
          onLoad={() => setLoaded(true)}
        />
        </div>
        <div className="d-grid text-center">

          <h3 style={{color:"#001533"}}>{carouselData[2].title}</h3>
          <p style={{color:"#001533"}}>{carouselData[2].content}</p>
       
        </div>
      </Carousel.Item>

    </Carousel>


        
      </>
    );
  };
  
export default OnBoarding;
  

  


