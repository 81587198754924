import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import FeatherIcon from 'feather-icons-react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { useNavigate } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";
import Link from '@mui/material/Link';

const drawerWidth = 300;

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const { logOut, user } = useUserAuth();

  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/login",{ replace: true });
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const userData = props.profileData;

  const drawer = (

    <div>
      <Toolbar >
        
      <IconButton sx={{ pt:3, paddingRight:0}}>
      <Avatar sx={{width: 70, height:70, }} alt="Finplay" src="https://cdn.dribbble.com/users/2009763/screenshots/4323795/andyclover.gif"/>
    </IconButton>
    <div className="container p-2">
      <div className="row">
          <Typography variant="h6" component="div" sx={{ mt:"1rem",ml:1 ,flexGrow: 1, color: "#001533", fontSize:18,}}>
          {userData && userData.phoneNumber}
          </Typography>
          </div>
       
          </div>
      </Toolbar>
      <List sx={{ pt:2, pl:1, pb:2 }}>
        
          <ListItem key='Invest' disablePadding>
            <ListItemButton onClick={()=>{navigate('/invest')}}>
              <ListItemIcon>
               <FeatherIcon icon ='dollar-sign' size="22"/>
              </ListItemIcon>
              <ListItemText primary='Invest' sx={{ fontSize:12}}/>
              <IconButton edge="end">
              <FeatherIcon icon ='chevron-right' size="14"  />
              </IconButton>
            </ListItemButton>
          </ListItem>
          <ListItem key='Learn' disablePadding>
            <ListItemButton onClick={()=>{navigate('/learn')}}>
              <ListItemIcon>
              <FeatherIcon icon ='book' size="22"/>
              </ListItemIcon>
              <ListItemText primary='Learn' sx={{ fontSize:12}}/>
              <IconButton edge="end">
              <FeatherIcon icon ='chevron-right' size="14"  />
              </IconButton>
            </ListItemButton>
          </ListItem>
          <ListItem key='Referral' disablePadding>
            <ListItemButton onClick={()=>{navigate('/earn')}}>
              <ListItemIcon>
               <FeatherIcon icon ='link' size="22"/>
              </ListItemIcon>
              <ListItemText primary='Referral' sx={{ fontSize:12}}/>
              <IconButton edge="end">
              <FeatherIcon icon ='chevron-right' size="14"  />
              </IconButton>
            </ListItemButton>
          </ListItem>

      </List>
      <Typography variant="h6" component="div" sx={{flexGrow: 1, color: "#ADADAD", fontSize:13, ml:3}}>
           ACCOUNT OPTIONS
          </Typography>
          <List sx={{ pt:2, pl:1, pb:2 }}>
        
        <ListItem key='withdraw' disablePadding>
          <ListItemButton >
            <ListItemIcon>
             <FeatherIcon icon ='arrow-down-left' size="22"/>
            </ListItemIcon>
            <ListItemText primary='Withdraw' sx={{ fontSize:12}}/>
            <IconButton edge="end">
              <FeatherIcon icon ='chevron-right' size="14"  />
              </IconButton>
          </ListItemButton>
        </ListItem>
        <ListItem key='transactions' disablePadding>
          <ListItemButton onClick={()=>{navigate('/notification')}}>
            <ListItemIcon>
             <FeatherIcon icon ='credit-card' size="22"/>
            </ListItemIcon>
            <ListItemText primary='Transactions' size='12'/>
              <IconButton edge="end">
              <FeatherIcon icon ='chevron-right' size="14"  />
              </IconButton>
          </ListItemButton>
        </ListItem>
      </List>
      <Typography variant="h6" component="div" sx={{flexGrow: 1, color: "#ADADAD", fontSize:13, ml:3}}>
      FIANCIAL TOOLS
          </Typography>
          <List sx={{ pt:2, pl:1, pb:2 }}>
          <ListItem key='sipCalculator' disablePadding>
        <Link sx={{width:'100%'}} href="https://finplay.in/sip-calculator/" underline="none" color="inherit">
          <ListItemButton>
            <ListItemIcon>
            <FeatherIcon icon ='repeat' size="22"/>
            </ListItemIcon>
            <ListItemText primary='SIP Calculator' />
            <IconButton edge="end">
              <FeatherIcon icon ='chevron-right' size="14"  />
              </IconButton>
          </ListItemButton>
          </Link>
        </ListItem>
        <ListItem key='MFCalculator' disablePadding>
        <Link sx={{width:'100%'}} href="https://finplay.in/lumpsum-calculator-mutual-fund/" underline="none" color="inherit">
          <ListItemButton>
            <ListItemIcon>
             <FeatherIcon icon ='users'size="22"/>
            </ListItemIcon>
            <ListItemText primary='Mutual Fund Calculator' />
            <IconButton edge="end">
              <FeatherIcon icon ='chevron-right' size="14"  />
              </IconButton>
          </ListItemButton>
          </Link>
        </ListItem>
      
        <ListItem key='FDCalculator' disablePadding>
        <Link sx={{width:'100%'}} href="https://finplay.in/fixed-deposit-fd-calculator/" underline="none" color="inherit">
          <ListItemButton>
            <ListItemIcon>
             <FeatherIcon icon ='shield' size="22"/>
            </ListItemIcon>
            <ListItemText primary='Fixed Deposit Calculator' />
            <IconButton edge="end">
              <FeatherIcon icon ='chevron-right' size="14"  />
              </IconButton>
          </ListItemButton>
          </Link>
        </ListItem>
      </List>
    
      
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          width:"100%",
          bgcolor: "white",
          
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{mr:'0rem'}}
          >
            <Avatar alt="Finplay" src="https://cdn.dribbble.com/users/2009763/screenshots/4323795/andyclover.gif"/>
          </IconButton>
          <div className="container" sx={{p:0}}>
      <div className="row">
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: "#001533", fontSize:16,}}>
          Hello User
          </Typography>
          </div>
          <div className="row">
          <Typography variant="body2" component="div" sx={{ flexGrow: 1, color: "#001533" , fontSize:12 }}>
          Remember learn, invest & earn
          </Typography>
          </div>
          </div>
          <IconButton sx={{ mr: 0 }} onClick={()=>{navigate('/notification')}}>
          <Badge badgeContent={props.badgeCount} sx={{'& .MuiBadge-badge': {color: '#ffffff',backgroundColor: '#393798',}}}>
            <NotificationsIcon />
          </Badge>
              </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="User Menu"
        >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
      
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
          <List sx={{ pt:2, pl:1, pb:2 }}>
        <ListItem key='logout' disablePadding>
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
             <FeatherIcon icon ='log-out'/>
            </ListItemIcon>
            <ListItemText primary='Logout'/>
          </ListItemButton>
        </ListItem>
      </List>

        </Drawer>
       
          
      </Box>
      
      
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
