import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
  

  const getBlogData = async () => {
    const snapshotData = await getLearnData('newsfeed');
    if (snapshotData) {
      var post = (snapshotData);
    }
    return post;
  };
  
  const getLearnData = async (type) => {
    try {
      const docRef = doc(db, "Learn", type);
      const snapshot = await getDoc(docRef);
      return snapshot.data();
    } catch (error) {
      console.log("Data", error);
      return null;
    }
  };

  export {getBlogData};

