import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
  

  const getTransactionData = async (uuid) => {
    const snapshotData = await getUserData(uuid);
    if (snapshotData) {
      var orderedkeys = (snapshotData);
    }
    return orderedkeys;
  };
  
  const getUserData = async (type) => {
    try {
      const docRef = doc(db, "users", type);
      const snapshot = await getDoc(docRef);
      return snapshot.data();
    } catch (error) {
      console.log("Data", error);
      return null;
    }
  };

  const notificationCountUpdate = async (uuid,count) => {
    console.log('called')
    try {
      const snapshot = await updateDoc(doc(db, "users", uuid), {notificationCount: count});
      return snapshot;
    } catch (error) {
      console.log("Data", error);
      return null;
    }
  };
  

  export {getTransactionData, notificationCountUpdate};

