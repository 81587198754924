
import { Routes, Route ,  BrowserRouter as Router} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Invest from "./pages/Invest";
import Learn from "./pages/Learn";
import Transaction from "./pages/Transaction";
import Earn from "./pages/Earn";
import Login from "./pages/Login";
import PhoneSignUp from "./pages/PhoneSignUp";
import ProtectedRoute from "./components/ProtectedRoute";
import Term from "./pages/Terms";
import { UserAuthContextProvider } from "./context/UserAuthContext";

function App() {
  return (
      
          <UserAuthContextProvider>
            <Routes>
              <Route path="/" element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              />
              <Route path="/invest" element={
                  <ProtectedRoute>
                    <Invest />
                  </ProtectedRoute>
                }
              />
              <Route path="/learn" element={
                  <ProtectedRoute>
                    <Learn />
                  </ProtectedRoute>
                }
              />
              <Route path="/earn" element={
                  <ProtectedRoute>
                    <Earn />
                  </ProtectedRoute>
                }
              />
              <Route path="/notification" element={
                  <ProtectedRoute>
                    <Transaction />
                  </ProtectedRoute>
                }
              />

              <Route path="/login" element={<Login />} />
              <Route path="/signup/:code" element={<PhoneSignUp />} />
              <Route path="/signup" element={<PhoneSignUp />} />
              <Route path="/terms" element={<Term />} />
            </Routes>
          </UserAuthContextProvider>
      
  );
}

export default App;
